import React, { useState } from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GlobalTop from "../components/globalTop"

const CustomerOpinions = ({
  allWpOpinion,
  znanyLekarzWpOpinion,
  facebookWpOpinion,
  googleWpOpinion,
}) => {
  const [tab, setTab] = useState("1")

  return (
    <Layout>
      <SEO title="Opinie klientów" />

      <GlobalTop title="Opinie klientów" />

      <section className="opinions-content">
        <div className="container">
          <div className="opinions__filters">
            <button
              className={tab === "1" && "current"}
              onClick={e => setTab("1")}
            >
              Wszystkie
            </button>
            <button
              className={tab === "2" && "current"}
              onClick={e => setTab("2")}
            >
              Znany Lekarz
            </button>
            <button
              className={tab === "3" && "current"}
              onClick={e => setTab("3")}
            >
              Google
            </button>
            <button
              className={tab === "4" && "current"}
              onClick={e => setTab("4")}
            >
              Facebook
            </button>
          </div>
          {tab === "1" ? (
            <div className="row">
              {allWpOpinion.nodes.map((node, index) => (
                <div className="col-md-6" key={index}>
                  <div className="opinions__tile">
                    <h4>
                      {node.title}{" "}
                      <img
                        className="img-fluid"
                        src={require(`../assets/images/logo-${node.acfOpinions.opinionSource}.svg`)}
                        alt={`Omeda-Med ${node.title}`}
                      />
                    </h4>
                    <div className="opinions__tile-stars">
                      {node.acfOpinions.opinionStars > 0 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 1 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 2 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 3 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 4 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                    </div>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: node.content.substring(0, 240),
                        }}
                      />
                      {node.content.length > 240 && "... "}
                      <Link to={`/opinie-klientow${node.uri}`}>Więcej</Link>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : tab === "2" ? (
            <div className="row">
              {znanyLekarzWpOpinion.nodes.map((node, index) => (
                <div className="col-md-6" key={index}>
                  <div className="opinions__tile">
                    <h4>
                      {node.title}{" "}
                      <img
                        className="img-fluid"
                        src={require(`../assets/images/logo-${node.acfOpinions.opinionSource}.svg`)}
                        alt={`Omeda-Med ${node.title}`}
                      />
                    </h4>
                    <div className="opinions__tile-stars">
                      {node.acfOpinions.opinionStars > 0 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 1 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 2 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 3 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 4 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                    </div>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: node.content.substring(0, 240),
                        }}
                      />
                      {node.content.length > 240 && "... "}
                      <Link to={`/opinie-klientow${node.uri}`}>Więcej</Link>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : tab === "3" ? (
            <div className="row">
              {googleWpOpinion.nodes.map((node, index) => (
                <div className="col-md-6" key={index}>
                  <div className="opinions__tile">
                    <h4>
                      {node.title}{" "}
                      <img
                        className="img-fluid"
                        src={require(`../assets/images/logo-${node.acfOpinions.opinionSource}.svg`)}
                        alt={`Omeda-Med ${node.title}`}
                      />
                    </h4>
                    <div className="opinions__tile-stars">
                      {node.acfOpinions.opinionStars > 0 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 1 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 2 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 3 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 4 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                    </div>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: node.content.substring(0, 240),
                        }}
                      />
                      {node.content.length > 240 && "... "}
                      <Link to={`/opinie-klientow${node.uri}`}>Więcej</Link>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : tab === "4" ? (
            <div className="row">
              {facebookWpOpinion.nodes.map((node, index) => (
                <div className="col-md-6" key={index}>
                  <div className="opinions__tile">
                    <h4>
                      {node.title}{" "}
                      <img
                        className="img-fluid"
                        src={require(`../assets/images/logo-${node.acfOpinions.opinionSource}.svg`)}
                        alt={`Omeda-Med ${node.title}`}
                      />
                    </h4>
                    <div className="opinions__tile-stars">
                      {node.acfOpinions.opinionStars > 0 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 1 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 2 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 3 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                      {node.acfOpinions.opinionStars > 4 ? (
                        <span></span>
                      ) : (
                        <span className="empty"></span>
                      )}
                    </div>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: node.content.substring(0, 240),
                        }}
                      />
                      {node.content.length > 240 && "... "}
                      <Link to={`/opinie-klientow${node.uri}`}>Więcej</Link>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        allWpOpinion: allWpOpinion {
          nodes {
            uri
            id
            slug
            title
            content
            excerpt
            acfOpinions {
              opinionSource
              opinionStars
            }
          }
        }
        znanyLekarzWpOpinion: allWpOpinion(
          filter: { acfOpinions: { opinionSource: { eq: "znanylekarz" } } }
        ) {
          nodes {
            uri
            id
            slug
            title
            content
            excerpt
            acfOpinions {
              opinionSource
              opinionStars
            }
          }
        }
        facebookWpOpinion: allWpOpinion(
          filter: { acfOpinions: { opinionSource: { eq: "facebook" } } }
        ) {
          nodes {
            uri
            id
            slug
            title
            content
            excerpt
            acfOpinions {
              opinionSource
              opinionStars
            }
          }
        }
        googleWpOpinion: allWpOpinion(
          filter: { acfOpinions: { opinionSource: { eq: "google" } } }
        ) {
          nodes {
            uri
            id
            slug
            title
            content
            excerpt
            acfOpinions {
              opinionSource
              opinionStars
            }
          }
        }
      }
    `}
    render={data => <CustomerOpinions {...data} />}
  />
)
